import { Component, OnDestroy, OnInit } from '@angular/core';

import { combineLatest } from 'rxjs';

import { BrandService } from 'src/app/modules/core/services/brand.service';
import { SettingsService } from 'src/app/modules/core/services/settings.service';

import { AppFacade } from 'src/app/modules/ngrx-store/app/app.facade';

import { ERROR_CODES } from 'src/app/modules/shared/constants/error-codes';
import { NAVIGATION_ROUTES } from 'src/app/modules/shared/constants/route-paths';
import { UnsubscribeOnDestroy } from 'src/app/modules/shared/utilities/unsubscribe-on-destroy';

@Component({
	selector: 'cp-error-page',
	templateUrl: './error-page.component.html',
	styleUrls: ['./error-page.component.scss'],
	standalone: false
})
export class ErrorPageComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy {
	routes = NAVIGATION_ROUTES;

	public ERROR_CODES = ERROR_CODES;

	myAccountUrl: string | undefined;

	tscUrl: string | undefined;

	errorHeader: string | undefined;

	errorText: string | undefined;

	errorCode: number | null | undefined;

	constructor(private appFacade: AppFacade,
		private brandService: BrandService,
		private settingsService: SettingsService) {
		
		super();
		
		combineLatest([this.settingsService.getSettings(),
			this.appFacade.errorCode$
		]).pipe(
			this.takeUntilDestroyed()
		).subscribe(
			([settings, errorCode]) => {
				const brand = this.brandService.getBrand();
				this.myAccountUrl = settings.brandConfigs[brand.name].myAccountUrl;
				this.tscUrl = settings.externalLinks.tscUrl;

				if (!errorCode) {
					// Default to 404 - invalid URL
					this.errorCode = ERROR_CODES.NOT_FOUND;
				} else {
					this.errorCode = errorCode;
				}

				switch (this.errorCode) {
					case ERROR_CODES.AGREEMENT_INVALID:
						this.errorHeader = 'Sales Agreement Not Found';
						this.errorText = `We're sorry we couldn't find a valid sales agreement. You can try again or return to <a href="${this.myAccountUrl}">MyAccount</a> for more information.`;
						break;
					case ERROR_CODES.PORTAL_NOT_ENABLED:
					case ERROR_CODES.FORBIDDEN:
						this.errorHeader = 'Uh oh! Something went wrong.';
						this.errorText = `Looks like you do not have access to Customer Portal Preview. Please try again or access <a href="${this.tscUrl}">TSC</a> to request access.`;	
						break;
					default:
						this.errorHeader = 'Uh oh! Something went wrong.';
						this.errorText = `We're sorry we encountered an error on our end. You can try again later or return to <a href="${this.myAccountUrl}">MyAccount</a> for more resources.`;	
						break;
				}
			});
	}

	ngOnInit(): void {
		this.appFacade.setDisclaimers([]);
	}

	override ngOnDestroy(): void {
		this.appFacade.resetDisclaimers();
		this.appFacade.resetErrorCode();
	}
}